<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <div class="w-full bg-softGrey80">
          <p
            class="md:w-10/12 m-auto p-4 text-xl text-pulpBlue text-center font-semibold"
          >
            Better English means a better life! With neo, you can quickly and
            effectively <br />reach the English proficiency needed for your
            career, regardless of your experience or fluency level.​
          </p>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Begin Your English Journey with neoAssess
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              Your organization will setup a time and place for you to take
              neoAssess for placement. This adaptive test should take 20 minutes
              to complete, less if you are just starting your English journey.
              The test will stop when neo determines your current English
              proficiency. You will be placed in neoStudy at the next CEFR
              level.
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-40 lg:h-52"
                src="@/assets/images/icons/A.-NEOSTUDY.png"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Study at Your Own Pace with neoStudy
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  You will be set up with a login and password for neoStudy.
                  neoStudy allows you to study in short lesson steps, earning
                  points as you complete exercises. It is available online and
                  as an app for your mobile devices, enabling you to study both
                  at school and at home. Your records remain synchronized at all
                  times.​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Conversational English Skills​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              In addition to your independent practice with neoStudy, you will
              also meet with your neo coach for 1:1 sessions. The sessions last
              20 minutes and are required at least once every two weeks. Study
              up to an hour at a time and then take a break. If you study
              continuously, you will earn less points, so study in short
              sessions and take breaks to keep your brain alert.​
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-72"
                src="@/assets/images/icons/neoCertification.jpeg"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  CEFR-Aligned Certificates
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  As you study with neo, you will complete the study
                  requirements for every Common European Framework of Reference
                  for Languages (CEFR) level before you can continue to the next
                  level. You will also complete a variety of Mastery Tests, the
                  sum of these progressive tests add up to all the CEFR level
                  requirements. When you are ready, neo will prompt you to take
                  the Certification Test for your current level. When you pass,
                  you will be awarded an internationally accepted certificate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-16">
        <AboutNeoCourses />
      </div>
    </div>
    <!-- <ul class="mb-20 flex justify-center">
      <li
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
      >
        Download more info
      </li>
    </ul> -->
  </div>
</template>

<script>
import AboutNeoCourses from "../components/AboutNeoCourses.vue";

export default {
  components: {
    AboutNeoCourses,
  },
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/14.NEW-student-section-adult.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/14.-male-employee-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/14.NEW-student-section-adult.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>